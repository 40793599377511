import { isEmpty } from 'lodash-es'
import { useQuery, useQueryClient } from '@tanstack/vue-query'
import { useSdk } from '../useSdk'
import type { UseCartErrors, UseCartInterface } from './useCart'
import { Logger } from '@/utils/logger'
import { normalizationCart } from '@/utils/normalize/cartServiceNormalize'
import { useUserStore } from '@/store/user'
import {
  readonly,
  ref,
  useGtmTrackEvent,
  useNuxtApp,
  useState,
  watch,
  useRuntimeConfig,
} from '#imports'
import type { ProductInterface } from '@/types/graphql'
import cartGetters from '@/utils/getters/cartGetters'
import type {
  AddItemsParams,
  RemoveBundleParams,
  UpdateBundleParams,
} from '@/types/sdk'
import type { CartServiceProps } from '@/types/cartService'

/**
 * Allows loading and manipulating cart of the current user.
 *
 * See the {@link UseCartInterface} for a list of methods and values available in this composable.
 */

let createCartPromise: Promise<any> | null = null

export function useCart<
  PRODUCT extends ProductInterface,
>(): UseCartInterface<any> {
  const loading = useState('loading', () => false)
  const cartError = ref<UseCartErrors>({
    addItem: null,
    removeItem: null,
    updateItemQty: null,
    load: null,
    clear: null,
  })
  const context = useNuxtApp()
  const apiState = context.$magento.config.state
  const { cartService } = useSdk()
  const queryClient = useQueryClient()
  const { addToCartFrameGlassesTrackEvent } = useGtmTrackEvent()
  const userStore = useUserStore()
  const config = useRuntimeConfig()

  const isEmployeeSite = config.public?.site === 'Employee'

  /**
   * Assign new cart object
   * @param newCart
   *
   * @return void
   */
  const setCart = (newCart: any): void => {
    Logger.debug('useCart.setCart', newCart)
    queryClient.setQueryData(['useCart-load'], newCart)
  }

  const isInCartBySkuId = (product: PRODUCT): boolean => {
    const cartFrameItems = cartGetters.getFrameItems(cart.value as any)
    return cartFrameItems.some(
      (cartItem: any) => cartItem?.product?.sku === product.sku,
    )
  }

  const createCart = async (forceUpdate = false) => {
    if (!userStore.loggedIn && !isEmployeeSite) return
    const apiStateCartIds = apiState.getCartIds()
    const storeCode = userStore?.store?.storeCode ?? ''
    const storeCartId =
      !isEmpty(storeCode) && (apiStateCartIds?.[storeCode] ?? '') === ''
    if (storeCartId || !apiStateCartIds || forceUpdate) {
      const createPromise = createCartPromise || cartService.createCart()
      createCartPromise = createPromise
      const { data } = await createPromise
      const cartId = {
        ...apiStateCartIds,
        [storeCode]: data.value?.cartId,
      }
      apiState.setCartIds(JSON.stringify(cartId), { path: '/' })
      createCartPromise = null
    }
  }

  const {
    refetch: loadCart,
    data: cart,
    error: loadCartError,
  } = useQuery(
    ['useCart-load'],
    async () => {
      await createCart()
      const res = await cartService.getCart()
      return res.data.value
    },
    {
      select: (oldCart) =>
        normalizationCart(oldCart as unknown as CartServiceProps),
      retry: 0,
      cacheTime: 5 * 60 * 1000,
      refetchOnMount: false,
      enabled: userStore.loggedIn && isEmployeeSite,
    },
  )

  watch(loadCartError, () => {
    cartError.value.load = loadCartError.value
  })

  const clear = async (): Promise<void> => {
    Logger.debug('useCart.clear')
    try {
      loading.value = true
      await createCart(true)
    } catch (err) {
      cartError.value.clear = err
      Logger.error('useCart/clear', err)
    } finally {
      loading.value = false
    }
  }

  const addItem = async (addItemsParams: AddItemsParams): Promise<void> => {
    Logger.debug('useCart.addItem')
    try {
      loading.value = true
      await createCart()
      const newCart = await cartService.addItems(addItemsParams)
      if (newCart.error.value) {
        throw newCart.error.value
      }
      queryClient.setQueryData(['useCart-load'], newCart.data.value)
      const frameSku = addItemsParams.items[0].sku
      const frameItems = cartGetters.getFrameItems(cart.value as any)
      const frameItem = frameItems.find(({ sku }: any) => sku === frameSku)
      if (frameItem) {
        addToCartFrameGlassesTrackEvent({
          sku: frameItem.sku,
          name: frameItem.product.name,
          price: frameItem.prices.row_total?.value,
        })
      }
      cartError.value.addItem = null
    } catch (err) {
      cartError.value.addItem = err
      Logger.error('useCart/addItem', err)
    } finally {
      loading.value = false
    }
  }

  const removeItem = async (params: RemoveBundleParams) => {
    Logger.debug('useCart.removeItem')
    try {
      loading.value = true
      const newCart = await cartService.removeBundle(params)
      if (newCart.error.value) {
        throw newCart.error.value
      }
      queryClient.setQueryData(['useCart-load'], newCart.data.value)
      cartError.value.removeItem = null
    } catch (err) {
      cartError.value.removeItem = err
      Logger.error('useCart/removeItem', err)
    } finally {
      loading.value = false
    }
  }

  const updateItem = async (params: UpdateBundleParams): Promise<void> => {
    Logger.debug('useCart.updateItem')
    try {
      loading.value = true
      const newCart = await cartService.updateBundle(params)
      if (newCart.error.value) {
        throw newCart.error.value
      }
      queryClient.setQueryData(['useCart-load'], newCart.data.value)
      cartError.value.updateItemQty = null
    } catch (err) {
      cartError.value.updateItemQty = err
      Logger.error('useCart/updateItemQty', err)
    } finally {
      loading.value = false
    }
  }

  return {
    setCart,
    cart,
    isInCartBySkuId,
    addItem,
    load: loadCart,
    removeItem,
    clear,
    updateItem,
    loading: readonly(loading),
    error: cartError,
  }
}

export default useCart
export * from './useCart'
